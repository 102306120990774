import React from 'react'
import ReactDOM from 'react-dom/client'

import 'filepond/dist/filepond.min.css'

import './assets/css/sandstone/bootstrap.css'
import './assets/css/bootstrap.custom.css'

import './assets/css/index.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
