import React, { Component, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Locations from './tools/Locations'

import Loadable from './ui-components/Loadable';

const Home = Loadable(lazy(() => import('./routes/Home')))
const Credits = Loadable(lazy(() => import('./routes/Credits')))
const SiteMap = Loadable(lazy(() => import('./routes/SiteMap')))
const AnalysisStart = Loadable(lazy(() => import('./routes/AnalysisStart')))
const AnalysisExecute = Loadable(lazy(() => import('./routes/AnalysisExecute')))
const AnalysisView = Loadable(lazy(() => import('./routes/AnalysisView')))
const SiteMaintenance = Loadable(lazy(() => import('./routes/SiteMaintenance')))
const Support = Loadable(lazy(() => import('./routes/Support')))
const Page404 = Loadable(lazy(() => import('./routes/Page404')))

class App extends Component {
	constructor(props) {
		super(props)

		library.add(fas, fab)
	}

	render() {
		return (
			<BrowserRouter>
				<Routes>
					<Route path={Locations.set('Home', '/')} element={<Home />} />
					<Route path={Locations.set('Support', '/support')} element={<Support />}>
						<Route path=':support' element={<Support />} />
					</Route>
					<Route path={Locations.set('Credits', '/credits')} element={<Credits />} />
					<Route path={Locations.set('SiteMap', '/site_map')} element={<SiteMap />} />
					<Route path={Locations.set('AnalysisStart', '/start')} element={<AnalysisStart />} />
					<Route path={Locations.set('AnalysisExecute', '/execute') + '/:uuid'} element={<AnalysisExecute />} />
					<Route path={Locations.set('AnalysisView', '/view')} element={<AnalysisView />} />
					<Route path={Locations.set('SiteMaintenance', '/maintenance')} element={<SiteMaintenance />} />
					<Route path='*' element={<Page404 />} />
				</Routes>
			</BrowserRouter>
		)
	}
}

export default App
