class Locations {
	Paths = {}

	get = (path) => this.Paths[path]
	set = (path, relUrl) => this.Paths[path] = relUrl

	navigation = (url) => window.location.href = url
}

export default new Locations()
