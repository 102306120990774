import React from 'react'

import { Image } from 'react-bootstrap'

import Spinner from "../assets/img/Spinner-1s-250px.svg"

const Loader = () => {
	return (
		<div className='bg-light text-center vw-100 vh-100'>
			<Image src={Spinner} alt='Loading...' />
		</div>
	)
}

export default Loader
